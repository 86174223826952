







import {Component, Vue} from 'vue-property-decorator';

@Component
export default class DropZone extends Vue {
  dragging = false;

  onDrop(ev: DragEvent) {
    const file = ev.dataTransfer?.files?.item(0)
    if(file)
      this.$emit('file', file)
  }

}

