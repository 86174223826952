


































































































































































import {Component, Vue} from 'vue-property-decorator';
import {ContestRound} from '@/models';
import Countdown from '@/components/Countdown.vue';
import axios from 'axios';
import Glitch from '@/components/effects/Glitch.vue';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
import DropZone from '@/components/DropZone.vue';
import {api} from '@/route';

@Component<ContestRoundSubmit>({
  components: {DropZone, Glitch, Countdown, FontAwesomeIcon},
  async beforeRouteEnter(to, from, next) {
    const contest = (await axios.get(api('/contest/current'))).data;
    if (contest.rounds.length === 0) {
      return next('/vbr3/'); 
    }
    let user: any = null;
    try {
      user = (await axios.get(api('/signup/data'))).data as any;
    } catch (e) {
    }

    next(vm => {
      vm.rounds = contest.rounds;
      vm.numParticipants = contest.participants;
      vm.numSubmitted = contest.submissions;
      vm.user = user || null;
      vm.activeRound = contest.rounds.length - 1;
    });
  }
})
export default class ContestRoundSubmit extends Vue {

  rounds: ContestRound[] = [];
  numParticipants = 0;
  numSubmitted = 0;
  file: File | null = null;
  user: any = null;
  status: string | null = null
  error: string | null = null

  activeRound = 0

  get round() {
    return this.rounds[this.activeRound]
  }

  get deadline() {
    return new Date(this.round.endDate);
  }

  get startDate() {
    return new Date(this.round.startDate)
  }

  onFileSelected(file: File) {
    const extension = file.name.split('.').pop();
    if (extension !== 'osz') {
      return alert('you have to submit a osz file dummy');
    }
    this.file = file;
  }

  async submit() {
    if(this.file) {
      const formData = new FormData();
      formData.append('file', this.file)
      const response = await axios.post(api(`/contest/submit`), formData, {
          headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      if(response.data?.status === 'success')
       this.status = 'success'
      else {
        this.status = 'error'
        this.error = response?.data.message as string
      }
    }
  }

  get signInRoute() {
    return api('/signup');
  }

  get downloadRoute() {
    return api('/contest/current/osz/' + this.round.id)
  }

  get roundHasStarted() {
    return new Date() > new Date(this.round.startDate) 
  }

  get roundIsOver() {
    return new Date() > new Date(this.round.endDate)
  }

  judges = [
    {
      name: 'Gillstar',
      id: 7948210,
      avatar: 'https://a.ppy.sh/7948210?1619992128.jpeg'
    },
    {
      name: 'Sharu',
      id: 5597639,
      avatar: 'https://a.ppy.sh/5597639?1515244823.png'
    },
    {
      name: 'Myxo',
      id: 2202645,
      avatar: 'https://a.ppy.sh/2202645?1637319158.jpeg'
    },
    {
      name: 'Mazzerin',
      id: 2942381,
      avatar: 'https://a.ppy.sh/2942381?1509229790.jpg'
    },
    {
      name: 'zhuxiaoyan',
      id: 5129592,
      avatar: 'https://a.ppy.sh/5129592?1650752607.jpeg',
    },
    {
      name: 'arkisol',
      id: 9331411,
      avatar: 'https://a.ppy.sh/9331411?1642204438.png',
    },
    {
      name: 'Lulu-',
      id: 4201715,
      avatar: 'https://a.ppy.sh/4201715?1641039505.png',
    },
  ];

}
